module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@4.25.0_gatsby@4.25.7_react-dom@16.14.0_react@17.0.0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P5FL77L","includeInDevelopment":true,"routeChangeEventName":"VirtualPageView","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.7_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chetwood website","short_name":"website","start_url":"/","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9678192f78a91af35192d90e976b3254"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-material-ui@3.0.1_@babel+core@7.24.3_@material-ui+styles@4.11.5_core-js@3.36.1_gatsby@4.25.7/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-offline@5.25.0_gatsby@4.25.7_react-dom@16.14.0_react@17.0.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.25.7_babel-eslint@10.1.0_react-dom@16.14.0_react@17.0.0_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
